import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SummaryApi from '../common';
import VerticalCard from '../components/VerticalCard';

const SearchProduct = () => {
  const location = useLocation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get('q') || '';
  const description = queryParams.get('description') === 'true'; // Interpretar como booleano

  const fetchProduct = async () => {
    setLoading(true);
    try {
      // Construye la URL con los parámetros de búsqueda
      let searchUrl = `${SummaryApi.searchProduct.url}?`;
      if (searchQuery) searchUrl += `q=${encodeURIComponent(searchQuery)}&`;
      if (description) searchUrl += `description=true&`;

      // Elimina el último '&' si existe
      searchUrl = searchUrl.endsWith('&') ? searchUrl.slice(0, -1) : searchUrl;

      const response = await fetch(searchUrl);
      const dataResponse = await response.json();
      setData(dataResponse.data);
    } catch (error) {
      console.error('Error al buscar productos:', error);
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProduct();
  }, [location.search]);

  return (
    <div className='container mx-auto p-4'>
      {loading && <p className='text-lg text-center'>Cargando ...</p>}
      <p className='text-lg font-semibold my-3'>Resultados de la búsqueda: {data.length}</p>
      {data.length === 0 && !loading && (
        <p className='bg-white text-lg text-center p-4'>No encontrado....</p>
      )}
      {data.length !== 0 && !loading && <VerticalCard loading={loading} data={data} />}
    </div>
  );
};

export default SearchProduct;
