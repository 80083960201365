// src/pages/PaymentSuccess.js
import { useEffect, useState } from 'react'; 
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import SummaryApi from '../common';
import displayCOPCurrency from '../helpers/displayCurrency';
import './PaymentSuccess.css';

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [transactionDetails, setTransactionDetails] = useState(null);

  const queryParams = new URLSearchParams(location.search);
  const referenceCode = queryParams.get('referenceCode');

  useEffect(() => {
    if (referenceCode) {
      fetchPaymentDetails(referenceCode);
    } else {
      console.error('No referenceCode provided.');
    }
  }, [referenceCode]);

  const fetchPaymentDetails = async (referenceCode) => {
    try {
      const response = await axios.get(
        SummaryApi.getPaymentDetails.url(referenceCode),
        { withCredentials: true }
      );
      setTransactionDetails(response.data);
    } catch (error) {
      console.error('Error al obtener detalles del pago:', error);
    }
  };

  return (
    <div className="payment-success-container">
      {transactionDetails && transactionDetails.status === 'Aceptada' ? (
        <>
          <h1>¡Gracias por tu compra!</h1>
          <p>Tu pago ha sido procesado exitosamente.</p>
          <div className="purchase-summary">
            <h2>Resumen de tu compra:</h2>
            <p><strong>Referencia:</strong> {transactionDetails.referenceCode}</p>
            <p><strong>Monto:</strong> {displayCOPCurrency(transactionDetails.amount)} {transactionDetails.currency}</p>
            {transactionDetails.reservationDetails && (
              <div className="product-details">
                <h3>Detalles de la Reserva:</h3>
                {transactionDetails.reservationDetails.map((item, index) => (
                  <div key={index} className="product-item">
                    <p><strong>Producto ID:</strong> {item.productId}</p>
                    <p><strong>Ciudad:</strong> {item.city}</p>
                    <p><strong>Plan:</strong> {item.planType}</p>
                    <p><strong>Opción de Plan:</strong> {item.planOption}</p>
                    <p><strong>Fecha de Inicio:</strong> {new Date(item.startDate).toLocaleDateString()} {item.startTime}</p>
                    <p><strong>Fecha de Fin:</strong> {new Date(item.endDate).toLocaleDateString()} {item.endTime}</p>
                    <p><strong>Productos Adicionales:</strong> {item.additionalProducts?.join(', ') || 'Ninguno'}</p>
                    <p><strong>Total Pagado:</strong> {displayCOPCurrency(item.totalPrice)} COP</p>
                  </div>
                ))}
              </div>
            )}
          </div>
          <button onClick={() => navigate('/')}>Volver al Inicio</button>
        </>
      ) : (
        <>
          <h1>Estado del Pago</h1>
          <p>No se obtuvo un estado de "Aceptada" para este pago. Verifica más tarde.</p>
          <button onClick={() => navigate('/')}>Volver al Inicio</button>
        </>
      )}
    </div>
  );
};

export default PaymentSuccess;
