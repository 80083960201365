import React, { useContext, useEffect, useState } from 'react';
import fetchCategoryWiseProduct from '../helpers/fetchCategoryWiseProduct';
import { Link } from 'react-router-dom';
import addToCart from '../helpers/addToCart';
import Context from '../context';
import scrollTop from '../helpers/scrollTop';
import PropTypes from 'prop-types';
import './CategoryWiseProductDisplay.css';

import displayCOPCurrency from '../helpers/displayCurrency';

const CategoryWiseProductDisplay = ({ category, heading }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const loadingList = new Array(5).fill(null);

  const { fetchUserAddToCart } = useContext(Context);

  const handleAddToCart = async (e, id) => {
    e.preventDefault();
    await addToCart(e, id);
    fetchUserAddToCart();
  };

  const fetchData = async () => {
    if (!category) {
      setData([]);
      setLoading(false);
      return;
    }

    setLoading(true);
    const categoryProduct = await fetchCategoryWiseProduct(category);
    setLoading(false);

    setData(categoryProduct?.data || []);
  };

  useEffect(() => {
    fetchData();
  }, [category]);

  return (
    <div className='container mx-auto px-4 my-6'>
      <h2 className='text-2xl font-semibold py-4'>{heading || 'Otras motocicletas'}</h2>
      {/* Cambiamos overflow-x-auto a overflow-x-scroll para mostrar la barra de desplazamiento */}
      <div className='flex space-x-4 overflow-x-scroll pb-2 custom-scrollbar'>
        {loading
          ? loadingList.map((_, index) => (
              <div
                key={`loading-${index}`}
                className='w-64 flex-shrink-0 bg-white rounded-md shadow-md animate-pulse'
              >
                <div className='h-40 bg-gray-200 rounded-t-md'></div>
                <div className='p-4 space-y-2'>
                  <div className='h-6 bg-gray-200 rounded'></div>
                  <div className='h-4 bg-gray-200 rounded w-3/4'></div>
                  <div className='flex space-x-2'>
                    <div className='h-4 bg-gray-200 rounded w-1/2'></div>
                    <div className='h-4 bg-gray-200 rounded w-1/2'></div>
                  </div>
                  <div className='h-8 bg-gray-200 rounded'></div>
                </div>
              </div>
            ))
          : data.length > 0
          ? data.map((product) => (
              <Link
                key={product._id}
                to={`/product/${product._id}`}
                className='w-64 flex-shrink-0 bg-white rounded-md shadow-md hover:shadow-lg transition-shadow duration-300'
                onClick={scrollTop}
              >
                <div className='h-40 bg-gray-100 flex items-center justify-center rounded-t-md overflow-hidden'>
                  {product.productImage?.[0] ? (
                    <img
                      src={product.productImage[0]}
                      alt={product.productName}
                      className='object-cover h-full w-full transform hover:scale-105 transition-transform duration-300'
                    />
                  ) : (
                    <p className='text-gray-500'>Sin Imagen</p>
                  )}
                </div>
                <div className='p-4 space-y-2'>
                  <h3 className='text-lg font-semibold truncate'>{product.productName || 'Producto'}</h3>
                  <p className='text-sm text-gray-500 capitalize'>{product.category || 'Categoría'}</p>
                  <div className='flex justify-between items-center'>
                    <span className='text-lg font-bold text-red-600'>
                      {displayCOPCurrency(product.sellingPrice || 0)} COP
                    </span>
                    {product.price > product.sellingPrice && (
                      <span className='text-sm text-gray-400 line-through'>
                        {displayCOPCurrency(product.price || 0)} COP
                      </span>
                    )}
                  </div>
                  <button
                    className='w-full bg-[#fea34a] hover:bg-[#e98c28] text-white py-2 rounded-md transition-colors duration-300'
                    onClick={(e) => handleAddToCart(e, product._id)}
                  >
                    Añadir a reserva
                  </button>
                </div>
              </Link>
            ))
          : (
              <p className='text-center w-full text-gray-500'>No se encontraron productos relacionados.</p>
            )}
      </div>
    </div>
  );
};

CategoryWiseProductDisplay.propTypes = {
  category: PropTypes.string.isRequired,
  heading: PropTypes.string,
};

export default CategoryWiseProductDisplay;
