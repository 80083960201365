// src/pages/Cart.js
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import displayCOPCurrency from '../helpers/displayCurrency';
import { MdDelete } from 'react-icons/md';
import './Cart.css';
import { removeFromCart, updateCartQuantity } from '../store/cartSlice';
import axios from 'axios';
import SummaryApi from '../common'; 
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const Cart = () => {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.cartItems);

  const [buyerEmail, setBuyerEmail] = useState('');
  const [buyerName, setBuyerName] = useState('');
  const [buyerLastName, setBuyerLastName] = useState('');
  const [docType, setDocType] = useState('CC');
  const [docNumber, setDocNumber] = useState('');

  const calculateTotal = () => {
    let total = 0;
    cartItems.forEach((item) => {
      if (
        item &&
        item.reservationDetails &&
        typeof item.reservationDetails.totalPrice === 'number' &&
        typeof item.quantity === 'number'
      ) {
        total += item.reservationDetails.totalPrice * item.quantity;
      }
    });
    return total;
  };

  const totalAmount = calculateTotal();
  const paymentAmount = Math.round(totalAmount * 0.10); // 10% del total como adelanto

  const handleRemoveFromCart = (productId) => {
    dispatch(removeFromCart({ productId }));
  };

  const handleQuantityChange = (productId, quantity) => {
    if (quantity < 1) quantity = 1;
    dispatch(updateCartQuantity({ productId, quantity }));
  };

  const isValidObjectId = (id) => {
    return /^[0-9a-fA-F]{24}$/.test(id);
  };

  // No se necesitan estados para paypalOrderID ni referenceCode si no las usas fuera
  const handleCreateOrder = async () => {
    // Validaciones
    if (!buyerEmail || !buyerName || !buyerLastName || !docType || !docNumber) {
      alert('Por favor, completa todos los campos del comprador.');
      return null;
    }

    for (const item of cartItems) {
      if (!isValidObjectId(item.productId)) {
        alert(`El productId ${item.productId} no es válido. Por favor, elimina este producto del carrito.`);
        return null;
      }
      const startDate = new Date(item.reservationDetails.startDate);
      const endDate = new Date(item.reservationDetails.endDate);

      if (endDate <= startDate) {
        alert(`La fecha de fin debe ser posterior a la fecha de inicio para el producto con ID: ${item.productId}`);
        return null;
      }
    }

    try {
      const paymentData = {
        amount: paymentAmount.toString(),
        currency: 'USD', 
        buyerEmail,
        buyerName,
        buyerLastName,
        docType,
        docNumber,
        reservationDetails: cartItems.map((item) => ({
          productId: item.productId,
          city: item.reservationDetails.city,
          planType: item.reservationDetails.planType,
          planOption: item.reservationDetails.planOption,
          startDate: item.reservationDetails.startDate,
          startTime: item.reservationDetails.startTime,
          endDate: item.reservationDetails.endDate,
          endTime: item.reservationDetails.endTime,
          additionalProducts: item.reservationDetails.additionalProducts,
          totalPrice: item.reservationDetails.totalPrice,
          quantity: item.quantity || 1,
        })),
      };

      // Llamar al endpoint para crear la orden de PayPal
      const response = await axios.post(
        SummaryApi.createPayPalOrder.url,
        paymentData,
        { withCredentials: true }
      );

      console.log('Respuesta createOrder PayPal:', response.data);

      // Retorna el orderID que PayPal necesita para abrir el popup
      return response.data.orderID;
    } catch (error) {
      console.error('Error al crear la orden en PayPal:', error);
      alert('Error al crear la orden en PayPal.');
      return null;
    }
  };

  const handleApprove = async (data) => {
    try {
      const { orderID } = data;
      const response = await axios.post(
        SummaryApi.capturePayPalOrder.url,
        { orderID },
        { withCredentials: true }
      );
      console.log('Pago capturado:', response.data);

      alert('Pago completado con éxito!');
      // Aquí podrías limpiar el carrito, redirigir a una página de éxito, etc.
    } catch (error) {
      console.error('Error al capturar el pago:', error);
      alert('Hubo un error al capturar el pago.');
    }
  };

  return (
    <div className="uniqueCart-container">
      {!cartItems || cartItems.length === 0 ? (
        <div className="uniqueCart-emptyContainer">
          <p className="uniqueCart-emptyMessage">
            No hay motos en la reserva.
          </p>
        </div>
      ) : (
        <div className="uniqueCart-content">
          <div className="uniqueCart-productList">
            {cartItems.map((item, index) => (
              <div key={index} className="uniqueCart-productCard">
                <div className="uniqueCart-productImage">
                  <img
                    src={
                      Array.isArray(item.reservationDetails.productImage) && item.reservationDetails.productImage.length > 0
                        ? item.reservationDetails.productImage[0]
                        : (item.reservationDetails.productImage || 'ruta/por/defecto.jpg')
                    }
                    alt={item.reservationDetails.productName || 'Producto'}
                    className="uniqueCart-productImg"
                  />
                  <div
                    className="uniqueCart-deleteButton"
                    onClick={() => handleRemoveFromCart(item.productId)}
                  >
                    <MdDelete />
                  </div>
                </div>
                <div className="uniqueCart-details">
                  <h2 className="uniqueCart-name">{item.reservationDetails.productName || 'Producto'}</h2>
                  <p className="uniqueCart-category">{item.reservationDetails.category || 'Categoría desconocida'}</p>
                  <div className="uniqueCart-reservationDetails">
                    <p><strong>Ciudad:</strong> {item.reservationDetails.city || 'No especificado'}</p>
                    <p><strong>Plan:</strong> {item.reservationDetails.planType || 'No especificado'}</p>
                    <p><strong>Opción del Plan:</strong> {item.reservationDetails.planOption || 'No especificado'}</p>
                    <p><strong>Fecha de Inicio:</strong> {new Date(item.reservationDetails.startDate).toLocaleDateString()} {item.reservationDetails.startTime || ''}</p>
                    <p><strong>Fecha de Fin:</strong> {new Date(item.reservationDetails.endDate).toLocaleDateString()} {item.reservationDetails.endTime || ''}</p>
                    <p><strong>Productos Adicionales:</strong> {item.reservationDetails.additionalProducts?.join(', ') || 'Ninguno'}</p>
                  </div>
                  <div className="uniqueCart-quantity">
                    <label>
                      <strong>Cantidad:</strong>{' '}
                      <input
                        type="number"
                        min="1"
                        value={item.quantity || 1}
                        onChange={(e) => handleQuantityChange(item.productId, parseInt(e.target.value))}
                        className="uniqueCart-quantityInput"
                      />
                    </label>
                  </div>
                  <div className="uniqueCart-price">
                    <p className="uniqueCart-totalPrice">
                      Subtotal: {displayCOPCurrency((item.reservationDetails.totalPrice || 0) * (item.quantity || 1))} COP
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="uniqueCart-summary">
            <h3 className="uniqueCart-totalAmount">
              Total: {displayCOPCurrency(totalAmount)} COP
            </h3>
            <h3 className="uniqueCart-paymentAmount">
              Adelanto (10%): {displayCOPCurrency(paymentAmount)} COP
            </h3>
          </div>
          <div className="uniqueCart-paymentForm">
            <h2>Pagar con PayPal</h2>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="uniqueCart-formGroup">
                <label>Nombre:</label>
                <input
                  type="text"
                  value={buyerName}
                  onChange={(e) => setBuyerName(e.target.value)}
                  required
                />
              </div>
              <div className="uniqueCart-formGroup">
                <label>Apellido:</label>
                <input
                  type="text"
                  value={buyerLastName}
                  onChange={(e) => setBuyerLastName(e.target.value)}
                  required
                />
              </div>
              <div className="uniqueCart-formGroup">
                <label>Correo electrónico:</label>
                <input
                  type="email"
                  value={buyerEmail}
                  onChange={(e) => setBuyerEmail(e.target.value)}
                  required
                />
              </div>
              <div className="uniqueCart-formGroup">
                <label>Tipo de documento:</label>
                <select
                  value={docType}
                  onChange={(e) => setDocType(e.target.value)}
                  required
                >
                  <option value="CC">Cédula de ciudadanía</option>
                  <option value="CE">Cédula de extranjería</option>
                  <option value="TI">Tarjeta de identidad</option>
                  <option value="PPN">Pasaporte</option>
                </select>
              </div>
              <div className="uniqueCart-formGroup">
                <label>Número de documento:</label>
                <input
                  type="text"
                  value={docNumber}
                  onChange={(e) => setDocNumber(e.target.value)}
                  required
                />
              </div>

              <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }}>
                <PayPalButtons
                  style={{ layout: "vertical" }}
                  createOrder={async () => {
                    const orderId = await handleCreateOrder();
                    if (!orderId) {
                      throw new Error('No se pudo crear la orden de PayPal.');
                    }
                    return orderId;
                  }}
                  onApprove={async (data) => {
                    await handleApprove(data);
                  }}
                  onError={(err) => {
                    console.error('Error en PayPal:', err);
                    alert('Hubo un error con el pago en PayPal.');
                  }}
                />
              </PayPalScriptProvider>

            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cart;
