import fz250_hero from "../assest/seguros-4rent.png";
import './Seguros.css'; 
import Buton from "../components/Button/Button";

const Seguros = () => {
  return (
    <>
      <div className="sg-hero-section">
        <div className="sg-hero-image" style={{ backgroundImage: `url(${fz250_hero})` }}></div>
        <div className="sg-overlay"></div>
        <div className="sg-content">
          <h1 className="sg-hero-title">Seguros 4 Rent</h1>
          <p className="sg-btitle">Todas nuestras motos están aseguradas.</p>
          <div className="sg-cta-button">
            <Buton to="/motos">Ver Motos</Buton>
          </div>
        </div>
      </div>

      <div className="sg-content-section">
        <div className="sg-container">
          <h1 className="sg-section-title" style={{ color: 'black' }}>
            Descubre los seguros disponibles para nuestras motos en 4rent
          </h1>
          <div className="sg-grid">
            <div className="sg-card">
              <h2 className="sg-card-title">Seguro Obligatorio de Accidentes de Tránsito “SOAT”</h2>
              <p className="sg-card-text">
                El SOAT en Colombia es un seguro obligatorio que cubre los gastos médicos y otros 
                costos asociados a lesiones por accidentes de tránsito, así como los gastos de 
                sepelio en caso de fallecimiento. Está diseñado para proteger a todas las personas 
                en territorio colombiano, sin importar su rol en el accidente, proporcionando 
                una cobertura básica para atención médica y otros gastos relacionados con accidentes viales.
              </p>
              <p className="sg-card-text">
                En 4Rent nos aseguramos de que todas nuestras motocicletas estén respaldadas por 
                el SOAT, lo cual brinda una cobertura adicional junto con nuestro seguro todo riesgo.
              </p>
            </div>
            <div className="sg-card">
              <h2 className="sg-card-title">Pólizas todo riesgo en 4Rent:</h2>
              <p className="sg-card-text">
                En 4Rent, nos enfocamos en brindar una cobertura completa y confiable a nuestros 
                clientes a través de nuestras pólizas todo riesgo. Estas pólizas están diseñadas 
                para proteger contra daños totales y robos, asegurando que nuestros clientes estén 
                respaldados en situaciones críticas.
              </p>
              <p className="sg-card-text">
                Además de esta cobertura esencial, también proporcionamos servicios de grúa a nivel 
                nacional para garantizar la asistencia rápida en caso de averías o emergencias en 
                la carretera. Nuestro compromiso con la seguridad y comodidad de nuestros clientes 
                se refleja en la diversidad de asistencias que ofrecemos, adaptadas a diferentes 
                necesidades y situaciones, proporcionadas por nuestros proveedores de confianza.
              </p>
              <p className="sg-card-text">
                Es importante destacar que, si bien nuestras pólizas todo riesgo son amplias, 
                es necesario tener en cuenta que no cubrimos <strong>pérdidas parciales o daños 
                parciales.</strong> Esta aclaración es fundamental para que nuestros clientes estén 
                informados y puedan tomar decisiones informadas sobre su protección y seguridad 
                mientras disfrutan de nuestros servicios de alquiler de motocicletas.
              </p>
            </div>
          </div>
          <div className="sg-cta-container">
            <Buton to="/contacto">¡Alquila Ahora!</Buton>
          </div>
        </div>
      </div>
    </>
  );
};

export default Seguros;
