// src/helpers/displayCurrency.js

/**
 * Formatea una cantidad como moneda en pesos colombianos (COP).
 * @param {number} amount - La cantidad a formatear.
 * @returns {string} - La cantidad formateada en COP.
 */
const displayCOPCurrency = (amount) => {
  if (typeof amount !== 'number') {
    return '0 COP';
  }
  return amount.toLocaleString('es-CO', { style: 'currency', currency: 'COP' });
};

export default displayCOPCurrency;
